import { Button, InputBase, styled, Typography, TypographyProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Measurement } from "@veris-health/med-data-ms/lib/v1";
import { VrsIcon, IconName } from "@veris-health/web-core";

export interface PatientInfoFieldProps extends TypographyProps {
  value?: InputValue;
  mask?: string;
  editable?: boolean;
  startEditing?: () => void;
  editMode?: boolean;
}

export type InputValue = string | Measurement;

const StyledNavigationItemText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  borderRadius: theme.shape.borderRadius,
  height: "22px",
  color: theme.veris.colors.neutrals["grey-4"],
  padding: theme.spacing(0.25, 1, 0, 1),
  "&:hover": {
    backgroundColor: theme.veris.colors.amethyst.light,
    color: theme.veris.colors.amethyst.dark,
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: theme.shape.borderRadius,
    color: theme.veris.colors.amethyst.soft,
    border: `1px solid ${theme.veris.colors.amethyst.soft}`,
    caretColor: theme.veris.colors.amethyst.soft,
    fontWeight: 400,
    fontSize: "1rem",
    height: "18px",
    maxWidth: "50px",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.25, 0, 0, 0.5),
    marginRight: theme.spacing(0.5),
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
  },
}));

export function PatientInfoField({
  mask,
  value,
  editable,
  startEditing,
  editMode,
  ...props
}: PatientInfoFieldProps): JSX.Element {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editingField, setEditing] = useState<boolean>(false);
  const [info, setInfo] = useState<InputValue>(value ?? "");

  useEffect(() => {
    if (!editMode) {
      setEditing(false);
      setShowEdit(false);
    }
  }, [editMode]);

  useEffect(() => {
    setInfo(value ?? "");
  }, [value]);

  return (
    <>
      {editingField && editMode ? (
        <InputMask
          mask={mask ?? ""}
          value={info as string}
          onChange={(e) => {
            setInfo(e.target.value);
          }}
          alwaysShowMask
        >
          {() => (
            <BootstrapInput size="small" margin="none" onMouseLeave={() => setShowEdit(false)} />
          )}
        </InputMask>
      ) : (
        <>
          {editable ? (
            <StyledNavigationItemText
              data-test-hook="start-edit-section"
              onMouseEnter={() => setShowEdit(true)}
              onMouseLeave={() => setShowEdit(false)}
            >
              {value}
              {showEdit && startEditing && (
                <Button
                  data-test-hook="start-editing-btn"
                  sx={{ padding: (theme) => theme.spacing(0, 0.2), minWidth: 0 }}
                  onClick={() => {
                    setEditing(true);
                    startEditing();
                  }}
                >
                  <VrsIcon name={IconName.Edit} size={12} />
                </Button>
              )}
            </StyledNavigationItemText>
          ) : (
            <Typography
              data-test-hook="start-edit-section-non-editable"
              variant="body"
              sx={{
                color: (theme) => theme.veris.colors.neutrals["grey-4"],
                padding: (theme) => theme.spacing(0.25, 1, 0, 1),
                wordBreak: "break-word",
                ...props.sx,
              }}
            >
              {info}
            </Typography>
          )}
        </>
      )}
    </>
  );
}
